.App {
  text-align: center;
}

*:focus {
  outline: 0 !important;
}

.App-logo {
  height        : 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height      : 100vh;
  display         : flex;
  flex-direction  : column;
  align-items     : center;
  justify-content : center;
  font-size       : calc(10px + 2vmin);
  color           : white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* OKTA */
#okta-sign-in .auth-org-logo {
  display   : block;
  margin    : 0 auto;
  min-width : 252px !important;
  min-height: 81px !important;
}

#okta-sign-in .auth-header {
  padding-left  : 75px !important;
  padding-right : 75px !important;
  padding-top   : 16px !important;
  padding-bottom: 16px !important;
  position      : relative;
  border-bottom : 1px solid #ddd;
  z-index       : 10;
  transition    : padding-bottom .4s;
}



/* .MuiAlert-filledInfo {
  color: #FFF !important;
  font-weight: 500 !important;
  background-color: #4285F4 !important;
} */

.MuiAlert-filledInfo {
  color           : #e6f4fe !important;
  font-weight     : 500 !important;
  background-color: #097afa !important;
}

.MuiAlert-filledSuccess {
  color           : #000 !important;
  font-weight     : 500 !important;
  background-color: #00ff09 !important;
}

.MuiAlert-filledWarning {
  color           : #000 !important;
  font-weight     : 500 !important;
  background-color: #fff700 !important;
}

.MuiAlert-filledError {
  color           : #FFF !important;
  font-weight     : 500 !important;
  background-color: #ff0101 !important;
}

.report-style-class {
  height: 100vh;
  margin: 1% auto;
  width : 100%;
}