.commonCircleStyles{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    font-size: 10px;
    color: #fff;
    line-height: 25px;
    text-align: center;
    background-color: #da5656;
    left: 40%;
    position: absolute;
}
.circle-error{
    background-color: #da5656;
}
.circle-warning{
    background-color: #ccbc40;
}

.circle-unmatched{
    background-color: #f79b49;
}

.circle-approval{
    background-color: #5b9ad5;
}

.circle-eligible{
    background-color: #5b9ad5;
}