.izenda-container {
  height: 100vh;
  margin-top: -24px;
  margin-right: -24px;
  margin-bottom: -24px;
  margin-left: -12px;
}

.quarter-screen {
  float: left;
  width: 40%;
  height: 40%;
  margin-left: 20px;
  margin-top: 20px;
  background-color: white;
}

.half-screen {
  float: left;
  width: 80%;
  height: 50%;
  margin-left: 20px;
  background-color: white;
}

.loader {
  border: 16px solid #f3f3f3;
  /* Light grey */
  border-top: 16px solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: -75px 0 0 -75px;
  position: absolute;
  left: 50%;
  top: 50%;
}

.loader-text {
  position: absolute;
  left: 45%;
  top: 60%;
  font-size: x-large;
  color: #3498db;
}

#loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

/* Fixes bootstrap conflict caret issue */
.izenda-container .dropdown-toggle::after {
  content: unset;
}

/* Fixes boostrap nav tab issue */
.izenda-container .nav {
  display: unset;
  flex-wrap: unset;
}

/* Fixes left nav report and templates selection display */
.izenda-container ul.nav.nav-tabs.tabs-navigation.u-bgDarkBlue {
    display: block;
}